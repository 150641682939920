const bindArrows = (left, right, slider, container) => {
  let leftArrows;
  let rightArrows;

  if (!container) {
    leftArrows = document.querySelectorAll(left);
    rightArrows = document.querySelectorAll(right);
  } else {
    leftArrows = container.querySelectorAll(left);
    rightArrows = container.querySelectorAll(right);
  }

  leftArrows.forEach((leftArrow) => {
    leftArrow.addEventListener('click', () => {
      slider.pause();
      slider.goTo('prev');
      slider.play();
    });
  });

  rightArrows.forEach((rightArrow) => {
    rightArrow.addEventListener('click', () => {
      slider.pause();
      slider.goTo('next');
      slider.play();
    });
  });

  const buttonsUpdate = () => {
    const info = slider.getInfo();
    leftArrows.forEach(arrow => arrow.classList.remove('_arrow-inactive'));
    rightArrows.forEach(arrow => arrow.classList.remove('_arrow-inactive'));
    if (info.displayIndex === 1) {
      leftArrows.forEach(arrow => arrow.classList.add('_arrow-inactive'));
    } else if (info.index + info.slideBy === info.slideCount) {
      rightArrows.forEach(arrow => arrow.classList.add('_arrow-inactive'));
    }
  };

  slider.events.on('transitionEnd', buttonsUpdate);
  buttonsUpdate();
};

export default bindArrows;