import MyTinySlider from './MyTinySlider';
import { onDomReady } from '../../components/dynamic/observer';

onDomReady(() => {
  if (!document.querySelector('[data-services-slider-container]')) {
    return;
  }
  const servicesSlider = new MyTinySlider({
    sliderContainer: '[data-services-slider-container]',
    sliderList: '[data-services-slider-list]',
    animateIn: 'fade-in',
    animateOut: 'fade-out',
    animateNormal: 'normal',
    nav: false,
    speed: 700,
    controls: true,
    mouseDrag: true,
    responsive: {
      1: {
        items: 1.2,
        slideBy: 1,
        center: true,
        gutter: 8,
      },
      768: {
        items: 3,
        gutter: 32,
        slideBy: 3,
        center: false,
      },
    },
  });
});