import Jax from '../../components/jax/jax';

class ArticleFilter {
  constructor(form) {
    this.form = form;
    this.checkedInputs = Array.from(form.querySelectorAll('input[type="checkbox"]'));

    this.init();
  }

  init() {
    this.bind();
  }

  bind() {
    this.bindChecked();
  }

  bindChecked() {
    const instance = this;
    this.checkedInputs.forEach((input) => {
      input.addEventListener('change', () => instance.request(instance.form));
    });
  }

  request(form) {
    const instance = this;
    const jax = new Jax(
      form.getAttribute('action'),
      form.getAttribute('method'),
    );
    jax.send(new FormData(form)).then(html => instance.responseHandler(html));
  }

  responseHandler(html) {
    const instance = this;
    const page = document.createElement('div');
    page.innerHTML = html;

    instance.replaceContent(page);

    document.dispatchEvent(new Event('DOMContentMutated'));
    document.dispatchEvent(new Event('BlogReplaced'));
  }

  replaceContent(page) {
    const newData = page.querySelector('[data-blog-content]');
    const currentData = document.querySelector('[data-blog-content]');
    currentData.innerHTML = newData.innerHTML;
  }
}

document.addEventListener('DOMContentLoaded', () => {
  setTimeout(() => {
    const form = document.querySelector('[data-article-filter]');
    if (form) {
      new ArticleFilter(form);
    }
  }, 1000);
});