require('../scss/app.scss');
require('./svg/svg');
require('../fonts/IBM_Plex_Sans/stylesheet.css');
require('../fonts/Montserrat/stylesheet.css');

window.noZensmooth = true;

require('../components/dynamic/bind');
require('../components/dynamic/observer');
require('./common/true-vh');
require('./common/js-validation');
require('./common/links');
require('./common/main');
require('./common/pagination');
require('./common/respond');
require('./common/map');
require('./common/webp-polyfill');
require('fslightbox');

require('./header/drop-menu');
require('./header/header');

require('./field/phone');

require('./filter/articleFilter');

require('./sliders/sliders');

// Раскоментить при необходимости
// require('./field/range.js');
// require('../components/smart-tabs/smart-tabs');
// require('./accordion/initializator');
// require('./accordion/switchable-accordion');
// require('./common/need-animation');
// require('./common/goal');
// require('./common/floating-field');