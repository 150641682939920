// найти drop-menu
// узнать высоту потомка
// присвоить высоту потомка drop-menu

import LiveEvent from '../../components/live/live';

new LiveEvent('click', '[data-menu-button]', ((e) => {
  e.preventDefault();
  const target = document.querySelector('.header__lower');
  const content = target.children[0];
  const targetHeight = content.clientHeight;

  if (!target.isOpened) {
    target.style.height = `${targetHeight}px`;
    target.isOpened = true;
    document.body.style.overflow = 'hidden';
    document.body.style.height = '100%';
  } else {
    target.style.height = '';
    target.isOpened = false;
    document.body.style.overflow = '';
    document.body.style.height = '';
  }
}));