import { onDomReady } from '../../components/dynamic/observer';

function initMapYandex() {
  ymaps.ready(() => {
    document.querySelectorAll('[data-map]').forEach((mapElement) => {
      const myMap = new ymaps.Map(mapElement, {
        center: [parseFloat(mapElement.dataset.lat), parseFloat(mapElement.dataset.lng)],
        zoom: mapElement.dataset.zoom,
        controls: ['zoomControl', 'fullscreenControl'],
      }, {
        searchControlProvider: 'yandex#search',
      });

      if (window.innerWidth <= 1024) {
        myMap.behaviors.disable('drag');
      }

      const mapItems = mapElement.querySelectorAll('[data-map-item]');

      mapItems.forEach((mapItem) => {
        myMap.geoObjects
          .add(new ymaps.Placemark([mapItem.dataset.lat, mapItem.dataset.lng], {
            balloonContentHeader: mapItem.dataset.name,
            balloonContent: mapItem.dataset.text,
          }, {
            iconLayout: 'default#image',
            iconImageHref: mapItem.dataset.icon,
            iconImageSize: [30, 30],
            iconImageOffset: [-15, -15],
          }));
      });
    });
  });
}

const initMapPending = () => {
  if (window.ymaps) {
    initMapYandex();
    return;
  }
  setTimeout(() => {
    initMapPending();
  }, 500);
};

onDomReady(() => initMapPending());