import LiveEvent from "../../components/live/live";
import {scrollToTarget} from "../../components/scroll/scroll";

new LiveEvent('click', '[data-scroll-link], .scroll-link', function scrollLink(e) {
  e.preventDefault();
  let target = null;
  const elementId = this.getAttribute('href');
  const targetId = elementId.replace('#', '');
  target = document.getElementById(targetId);
  let offset = parseInt(this.dataset.offset) || 0;
  if (target) {
    scrollToTarget(target, offset);
  }
});

// adds class for header when the menu is opened
new LiveEvent('click', '[data-header-button]', function toggleLink(e) {
  e.preventDefault();
  const target = document.querySelector(this.dataset.header);
  target.classList.toggle(this.dataset.headertoggle);
});

new LiveEvent('click', '[data-toggle-link], .toggle-link', function toggleLink(e) {
  e.preventDefault();
  const target = document.querySelector(this.dataset.selector);
  target.classList.toggle(this.dataset.toggle);
});